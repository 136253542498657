import React, { useEffect, useState } from 'react'
import Wrapper from '../../components/wrapper'
import globalData from '../../../globalData.json'
import CandidateForm from '../../components/pageComponents/candidateForm'
import { DiamondIcon } from '../../components/icons'

const OPTION_LIST = [
  {
    title: 'Long-Term Access, Lasting Results',
    desc: 'With six months of access, you have the time and stability to build relationships and find the right fit — without pressure.',
  },
  {
    title: 'Bigger Savings, Smarter Spending',
    desc: 'Pay less per month compared to shorter plans and optimize your job search investment with greater access to premium listings.',
  },
  {
    title: 'Exclusive Career Growth Tools',
    desc: 'Access expert guidance on resume building, interview strategies, and industry trends to position yourself for top opportunities.',
  },
]

export default function BiAnnualJoinNow({ location }) {
  const [subscription, setSubscription] = useState(null)
  const params = new URLSearchParams(location.search)

  useEffect(() => {
    let _prices = globalData?.CandidateCatalog?.prices?.length
      ? globalData?.CandidateCatalog?.prices
      : []
    const monthlyPrice = _prices.find(item => item.name === 'Bi-Annual')
    if (monthlyPrice) {
      setSubscription(monthlyPrice)
    }
  }, [])

  return (
    <Wrapper
      location={location}
      title="Biotech Sales | MedReps"
      description="Access the latest salary trends and insights in biotech sales, with valuable data and analysis to help you make informed decisions about your medical sales career."
    >
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 my-8 items-center">
          <div className="p-8 md:p-0">
            <h4 className="text-[#6d1e36] leading-[1.25] text-[52px] font-bold font-georgia">
              Stay Ahead with a Smarter Investment
            </h4>
            <img src="/images/join-now-bi-annual.png" />
            <p className="leading-[1.25] font-arial text-xl mt-10">
              Our{' '}
              <strong className="text-[#c6004c]">Bi-Annual Membership</strong>{' '}
              gives you six months of unlimited access to top medical sales
              jobs, exclusive hiring insights, and expert career resources—so
              you can strategize, apply with confidence, and secure your ideal
              role.
            </p>
          </div>
          <div className="p-8">
            <CandidateForm
              subscription={subscription}
              promoCode={params.get('utm_promocode')}
            />
          </div>
        </div>
        <div className="gap-4 block md:grid grid-cols-3 mb-4">
          {OPTION_LIST.map(item => (
            <div className="bg-white shadow-profileCard rounded-lg p-8 m-4">
              <div className="w-[32px]">
                <DiamondIcon fillColor={'#6d1e36'} />
              </div>
              <h1 className="m-0 sm:mb-3 font-playfair text-headingThree font-normal">
                {item.title}
              </h1>
              <p className="font-sans text-sm md:text-base mb-0">{item.desc}</p>
            </div>
          ))}
        </div>
      </div>
    </Wrapper>
  )
}
